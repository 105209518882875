  .card-enroll {
    .price-dropdown {
      top: 34px !important;
      right: 0 !important;
      left: unset !important;
      padding-right: 5px;

      ::-webkit-scrollbar {
        width: 7px;
        display: block;
        background: unset !important;
      }

      ::-webkit-scrollbar-thumb {
        background: #666666;
        border-radius: 10px;
      }
    }

    .dropdown-item {
      padding-right: 2px;
      width: 120px;
      height: 250px;
      overflow-y: scroll;
    }
  }

  .let-us-help {
    .let-us-help-header {
      border: none;
      height: 0;

      &.border-b {
        border-bottom: none;
      }
    }

    padding: 20px;

    .let-us-help-title {
      font-size: 30px;
      font-weight: 600;
    }

    .let-us-help-message {
      font-size: 18px;
      font-weight: 400;
    }

    button {
      border-radius: 30px;

      svg {
        color: #111928;
      }

      &.bg-blue-700 {
        background: #34A9DD;
        color: #ffffff;
      }

      &.border-gray-300 {
        color: #34A9DD;
        border-color: #34A9DD;
      }
    }
  }

