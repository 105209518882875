.uppy-Dashboard {
    height: 200px;
}

.upload-icon {
    background-color: #f97317;
    height: 4rem;
}

.input-checkbox-course {
    &:checked {
        background-color: #34a9dd;
    }
}
.add-degree-title {
    border-top: 1px solid #d1d5db;
    border-bottom: 1px solid #d1d5db;
}

.css-ovhu1a-control {    
    background-color: #F9FAFB !important;
    border: 1px solid #F3F4F6 !important;
 }
 .css-1xc3v61-indicatorContainer{
    color: #6B7280 !important
 }
