.my-purchases {
    .banner {
        padding: 30px 80px;
        width: 100%;
        height: 105px;
        background: #111928;

        .text {
            font-weight: 600;
            font-size: 30px;
            color: #FFFFFF;
        }
    }

    .button-change,
    .button-upgrade {
        &.bg-blue-700 {
            border: 1px solid #34A9DD;
            border-radius: 30px;
            background: #34A9DD;
            color: #ffffff;

            &:hover {
                background: transparent;
                color: #34A9DD;
            }
        }

        &.bg-blue-600 {
            border: 1px solid #34A9DD;
            border-radius: 30px;
            background: #34A9DD;
            color: #ffffff;

            &:hover {
                background: transparent;
                color: #34A9DD;
            }
        }
    }

    .button-renew {
        margin-left: 15px;

        &.bg-blue-700 {
            border: 1px solid #F05252;
            border-radius: 30px;
            background: #F05252;
            color: #ffffff;

            &:hover {
                background: transparent;
                color: #F05252;
            }
        }

        &.bg-blue-600 {
            border: 1px solid #F05252;
            border-radius: 30px;
            background: #F05252;
            color: #ffffff;

            &:hover {
                background: transparent;
                color: #F05252;
            }
        }

        &:focus {
            box-shadow: unset !important;
        }
    }

    .content {
        .plan-overview {
            justify-self: center;
            box-sizing: border-box;
            width: 100%;
            border: 1px solid #E5E7EB;
            border-radius: 10px;

            .card-title {
                padding: 15px 20px;
                width: 100%;
                background: #F9FAFB;
                border-style: solid;
                border-color: #E5E7EB;
                border-radius: 10px 10px 0px 0px;

                h1 {
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 150%;
                }

                .descriotion {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 150%;
                }
            }

            .no-plan {
                padding: 18px 20px;
                font-weight: 400;
                font-size: 14px;
            }

            .content {
                font-weight: 400;
                font-size: 14px;

                .row {
                    border-bottom: 1px solid #E5E7EB;
                    padding: 18px 20px;
                }

                .row-2 {
                    border-bottom: 1px solid #E5E7EB;
                    padding: 15px 20px;
                }

                .last {
                    border-bottom: unset;
                }
            }
        }

        .no-billing {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            padding-top: 100px;
            padding-bottom: 100px;
            font-weight: 500;
            font-size: 18px;
            line-height: 18px;
            color: #9CA3AF;
        }
    }

    @media (max-width: 375px) {
        .plan-overview {
            max-width: 335px;
        }
    }

    .next-billing-item {
        display: flex;
        flex-flow: row;
        height: 20px;
        gap: 5px;

        .next-billing-badge {
            font-size: 12px;
            font-weight: 500;

            span {
                color: #E02424;
            }
        }
    }

    .padding-block {
        margin-top: 30px;
    }

    .status {
        border-radius: 6px;
        padding: 4px 10px;
        font-weight: 500;
        font-size: 12px;
        line-height: 150%;
        margin-left: 10px;
    }

    .status.Failed{
        background: #FDE8E8;
        color: #E02424;
    }
    .status.Paid{
        background: #DEF7EC;
        color: #31C48D;
    }
    .status.Refund{
        background: #FDF6B2;
        color: #C27803;
    }
}