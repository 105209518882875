.welcome-page {
  background-color: #F3F4F6;

  .box {
    background-color: white;
    // background-image: url(../../assets/image/yam.jpg);
    width: 600px;
    padding: 50px;
    border-radius: 10px;

    @media (max-width: 525px) {
      max-width: 390px;
    }

    button {
      font-size: 16px;
      width: 335px;
      border-radius: 30px;
    }

  }

  @media (max-width: 525px) {
    padding: 0;
  }
}