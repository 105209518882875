.custom-language{
  position: relative;
  button{
    font-size: 14px;
    background-color: #EBF5FF;
    color: #34A9DD;
    font-weight: 500;
    border-radius: 30px;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  ul{
    width: 105px;
  }
}