@media (max-width: 640px) {
    .admin-header {
        padding-top: 62px !important;
    }
  }
.avatar {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 37px;
        height: 37px;
        background: #FF8A4C;
        border-radius: 30px;
        text-transform: uppercase;
        color: white;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
    }
  