.card-item {
    img {
        max-width: 100%;
        height: 170px;
        margin: 0 auto;
    }

    .view-details {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .bg-green-50 {
            background: #DEF7EC;
            border-radius: 4px;
            color: #31C48D;
            font-size: 16px;
            font-weight: 600;
        }
    }

    @media (max-width: 567px) {
        .program-title {
            width: 250px;
        }
    }
}

// for mobile