.degree-details-tabs {
    button {
        border: none;
        background: transparent;
        &.text-gray-500 {
            color: #6B7280;
            font-size: 14px;
            font-weight: 600;
        }

        &.text-blue-600 {
            color: #111928;
            font-size: 14px;
            font-weight: 600;
            border-bottom: 2px solid #111928;
        }

        &:hover {
            background: transparent;
        }
        &:focus-visible {
            outline: none;
        }
    }
}


.tab-group {
    button {
        background-color: unset !important;
        border-color: transparent !important;
        border-radius: unset !important;
        &[aria-selected = "true"]{
            border-color: #1C64F2 !important;
        }
    }

}
@media screen and (max-width: 3600px) {
    .container-card-enroll {
        position: absolute;
        top: 96px;
        right: 32px;
    }
      .container-degree {
        width: 70%;

    }
}

@media screen and (max-width: 1300px) {
     .container-degree {
        width: 100%;
    }

    .container-card-enroll {
        position: static;
        padding: 30px 50px 0 50px;
    }

    .card-enroll {
        max-width: 100%;
        width: 100%;

        .container-card {
            display: flex;
            justify-content: space-between;

            .information {
                width: 50%;
            }

            .enroll {
                width: 50%;
            }
        }
    }

    .includes {
        display: flex;
        flex-wrap: wrap;

        .degree-item {
            width: 50%;
        }

        .degree-item-icon {
            min-width: 32px;
        }
    }

}

@media screen and (max-width: 640px) {
     .container-degree {
        width: 100%;
    }
    .container-card-enroll {
        width: 100%;

        .card-enroll {
            max-width: 100%;
            width: 100%;

            .container-card {
                display: block;

                .information {
                    width: 100%;
                }

                .enroll {
                    width: 100%;
                }
            }
        }
           .includes {
        display: block;

        .degree-item {
            width: 100%;
        }

        .degree-item-icon {
            min-width: 32px;
        }
    }
    }
    .container-degree-courses {
        display: block;
        .instructor {
            width: 40%;
        }
        .summary-course {
            margin-bottom: 0;
        }
    }

}
