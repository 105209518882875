@import './mixins';

.sidebar {
  z-index: 1;
;

  .px-3 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .border-t {
    border-top-width: 0 !important;
  }

  .pt-4 {
    padding-top: 0 !important;
  }

  .py-4 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }

  ul {
    li {
      .rounded-lg {
        border-radius: 0 !important;
      }

      .p-2 {
        padding: 13px 15px;
      }

      cursor: pointer;

      a {
        padding: 7px 0;
      }
    }

    .bg-gray-100 {
      border-left: solid 3px rgb(17 24 39 / var(--tw-bg-opacity));

      .text-gray-500 {
        color: #111928;
      }
    }
      .sidebar-item{
    span {
      padding-left: 10px !important;
    }
  }
  }

}

//hidden md:block
//for mobile-tablet
@media screen and (max-width: 1024px) {
  .sidebar {
    width: 60px;

    .pl-5 {
      padding-left: 10px;
    }

    .pt-4 {
      padding-top: 5px !important;
    }

    .pb-5 {
      padding-bottom: 15px;
    }

    .mb-6 {
      margin-top: 8px;
      margin-bottom: 0;
    }

    aside {
      width: 100%;
    }

    ul {
      li {
        a {
          span {
            display: none;
          }
        }
      }
    }
  }
}

//for mobile
@media screen and (max-width: 640px) {
  .sidebar {
    display: none;
  }
  .mt-content-mb{
    margin-top: 76px;
  }
  .nav-menu-mobile {
    z-index: 1;
    position: fixed;
    background: white;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .show-dropdow-menu{
    transition: color 1s cubic-bezier(0.32, 0, 0.67, 0);
    transition: 0.5s;
    z-index: 2;
    position: fixed;
    background: white;
    width: 100%;
    height: 100%;
    padding: 10px;
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    aside{
      div{
        display: flex;
        flex-direction: column;
        height: auto;
      }
    }
    .w-64{
      width: 100%;
    }
    .pt-4 {
      padding-top: 0;
    }
    .border-t {
      border-top-width: 0;
    }
    .mt-4 {
      margin-top: 0;
    }
    button{
      transition: color 1s cubic-bezier(0.32, 0, 0.67, 0);
      transition: 0.5s;
      align-self: end;
    }
    svg{
      color: #111928;
    }
    .sign-out{
      svg{
      color: #F05252 !important;}
    }
  }
}
