.footer{
    img{
        height: 28px;
    }
    .custom-language{
        button{
            background-color: transparent;
            color: white;
            font-size: 12px;
            border: solid 1px white;
        }
        .px-3 {
            padding-left: 0;
            padding-right: 0;
        }
        .py{

        }
        .rotate-45{
            display: none;
        }
    }
}
