.courses {
    padding-top: 82px;

    .filter {
        max-width: inherit;
        width: 100%;
        padding-right: 60px;
        top: 69px
    }

    .bg-filter {
        background-color: #ffffff;

    }

    .search-course-input>div {
        input {
            border: 1px solid #F3F4F6 !important;
            background-color: #F9FAFB;

            &:focus {
                border: 2px solid #34aadc !important;
                box-shadow: unset !important;
            }
        }

        input::placeholder {
            color: #111928;
        }
    }

    #scrollableDiv {
        overflow: auto;
    }

    .infinite-scroll-component__outerdiv {
        max-height: calc(100vh - 69px - 82px);
        min-height: calc(100vh - 69px - 82px);
    }

    .mh-scroll {
        min-height: calc(100vh - 217px);
    }

    .mh-noresult {
        min-height: calc(100vh - 226px - 2.5rem);
    }

    .loading-scroll {
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
    }

    .mb--90 {
        margin-bottom: 60px;
    }

    ::-webkit-scrollbar {
        display: none;
    }

    .courses__nav {
        width: 75%;
        display: flex;
    }

    .courses__nav-item {
        padding-right: 0.75rem;
    }



    .courses-filter {
        display: flex;
        width: 100%;
    }

    .courses-search {
        width: 40%;
    }

    @media (max-width: 1300px) {
        padding-top: 110px;

        .courses__nav {
            width: 100%;
            display: flex;
        }

        .courses-filter {

            width: 75%;
        }

        .courses__nav-item {
            width: 33.3%;
        }

        .courses-search {
            width: 25%;
        }

        .infinite-scroll-component__outerdiv {
            max-height: calc(100vh - 85px - 90px);
            min-height: calc(100vh - 85px - 90px);
        }

        .filter {
            max-width: inherit;
            width: 100%;
            padding-right: 60px;
            top: 85px
        }

        .mh-scroll {
            min-height: calc(100vh - 233px - 0.5rem);
        }

        .mh-noresult {
            min-height: calc(100vh - 250px - 2.5rem);

        }
    }

    @media (max-width: 1024px) {
    padding-top: 132px;
        .courses__nav {
            width: 100%;
            display: block;
        }

        .pacing {
            padding-right: 0px
        }

        .courses-filter {

            width: 100%;
        }

        .courses__nav-item {
            width: 33.3%;
        }

        .courses-search {
            width: 100%;
        }

        .infinite-scroll-component__outerdiv {
            max-height: calc(100vh - 85px - 132px);
            min-height: calc(100vh - 85px - 132px);
        }

        .filter {
            max-width: inherit;
            width: 100%;
            padding-right: 60px;
            top: 85px
        }

        .mh-scroll {
            min-height: calc(100vh - 233px - 3rem);
        }

        .mh-noresult {
            min-height: calc(100vh - 243px - 5.5rem);

        }
    }



    @media (max-width: 767px) {
        .courses__nav {
            width: 100%
        }

        .courses-filter {
            display: flex;
            width: 100%;
        }

        .courses__nav-item {
            width: 33.3%;
        }

        .infinite-scroll-component__outerdiv {
            max-height: calc(100vh - 85px - 132px);
            min-height: calc(100vh - 85px - 132px);

        }

        .filter {
            max-width: inherit;
            width: 100%;
            padding-right: 60px;
            top: 85px
        }

        .mh-scroll {
            min-height: calc(100vh - 302px - 2.5rem);
        }

        .mh-noresult {
            min-height: calc(100vh - 305px - 5.5rem);

        }

    }

    @media (max-width: 500px) {

        padding-top: 232px;

        .infinite-scroll-component__outerdiv {
            max-height: calc(100vh - 85px - 228px);
            min-height: calc(100vh - 85px - 228px);
        }

        .filter {
            max-width: inherit;
            width: 100%;
            padding-right: 30px;

        }

        .mh-scroll {
            min-height: calc(100vh - 407px - 2rem);

        }

        .mh-noresult {
            min-height: calc(100vh - 417px - 4.5rem);

        }

        .courses__nav {
            flex-direction: column;
        }

        .courses-filter {
            display: block;
            width: 100%;
        }

        .courses__nav-item {
            width: 100%;
            margin: 5px 0;
            padding-right: 0px;
        }

    }
}