  .card-enroll {
    .price-dropdown {
      top: 34px !important;
      right: 0 !important;
      left: unset !important;
      padding-right: 5px;

      ::-webkit-scrollbar {
        width: 7px;
        display: block;
        background: unset !important;
      }

      ::-webkit-scrollbar-thumb {
        background: #666666;
        border-radius: 10px;
      }
    }

    .dropdown-item {
      padding-right: 2px;
      width: 120px;
      height: 250px;
      overflow-y: scroll;
    }
  }

  .custom-button-style {
    background-color: #1B65F2;
    transition: background-color 0.2s ease-in-out;
  
    &:hover {
      background-color: #34A9DD;
    }
  }