.button-edit {
  border: 1px solid #34a9dd;
  background-color: white !important;

  &:hover {
    background-color: #34a9dd !important;
  }

  &:focus {
    box-shadow: unset !important;
  }

  span {
    color: #35aade;

    &:hover {
      color: white;

    }
  }
}

.button-deactivate {
  background-color: white !important;
  border: 1px solid #F05252;

  &:hover {
    background-color: #F05252 !important;

  }

  &:focus {
    box-shadow: unset !important;

  }

  span {
    color: #F05252;

    &:hover {
      color: white;

    }
  }
}

.button-activate {
  background-color: white !important;
  border: 1px solid #9ca3af;

  &:hover {
    background-color: #9ca3af !important;
  }

  &:focus {
    box-shadow: unset !important;
  }

  span {
    color: #9ca3af;

    &:hover {
      color: white;

    }
  }
}

.button-export {
  background-color: white !important;
  border: 1px solid #9ca3af;

  &:hover {
    background-color: #9ca3af !important;
  }
  span {
    color: #212121;
    &:hover {
      color: white;

    }
  }
}

.add-action {
  &.bg-blue-700 {
    border: 1px solid #34A9DD;
    border-radius: 30px;
    background: #34A9DD;
    color: #ffffff;

    &:hover {
      background: transparent;
      color: #34A9DD;
    }
  }

  &.bg-blue-600 {
    border: 1px solid #34A9DD;
    border-radius: 30px;
    background: #34A9DD;
    color: #ffffff;

    &:hover {
      background: transparent;
      color: #34A9DD;
    }
  }
}

tr {
  border-bottom: 1px solid rgba(0, 0, 0, 0.08) !important;
}