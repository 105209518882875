.profile-page {
  width: 100%;
  background-color: #F3F4F6;
  .profile-container {
    width: 400px;
    border: 1px solid #E5E7EB;
    border-radius: 8px;
    padding: 50px;
    .profile-title {
      font-style: normal;
      font-weight: 600;
      font-size: 30px;
    }
  }

}
