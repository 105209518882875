.admin-main-layout {
  .primary{
    color: #34A9DD;
  }
  .hover\:text-blue-700:hover, .hover\:text-primary:hover {
    color: #34A9DD;
  }

  [type='search']:focus {
    outline: none;
    border: 1px solid #E5E7EB;
    box-shadow: none;
  }

  button {
    border-radius: 30px;
    height: 37px;
  }

  .add-action {
    &.bg-blue-700 {
      border: 1px solid #34A9DD;
      border-radius: 30px;
      background: #34A9DD;
      color: #ffffff;
      &:hover {
        background: transparent;
        color: #34A9DD;
      }
    }
    &.bg-blue-600 {
      border: 1px solid #34A9DD;
      border-radius: 30px;
      background: #34A9DD;
      color: #ffffff;
      padding: 8px 16px;
      &:hover {
        background: transparent;
        color: #34A9DD;
      }
    }
  }

  table {
    tr {
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
      border-bottom: 1px solid rgba(0, 0, 0, 0.08);
      td {
        button {
          border-radius: 30px;
          &.bg-blue-600 {
            border: 1px solid #34A9DD;
            background: transparent;
            color: #34A9DD;
            padding: 8px 16px;
            &:hover {
              background: #34A9DD;
              color: #ffffff;
            }
          }
          &.bg-red-500 {
            border: 1px solid #F05252;
            background: transparent;
            color: #F05252;

            &:hover {
              background: #F05252;
              color: #ffffff;
            }
          }
          &.text-blue-600 {
            border: 1px solid #34A9DD;
          }


          &.text-yellow-400 {
            border: 1px solid #E3A008;
            color: #E3A008;
            &:hover {
              background: #E3A008;
              color: #ffffff;
            }
          }
          &.border-yellow-300 {
            border: 1px solid #E3A008;
          }
        }
      }
    }
  }

  [class*=-indicatorSeparator] {
    display: none;
  }
}

.admin-modal {
  [class*=-indicatorSeparator] {
    display: none;
  }
  [class*=-multiValue] {
    background: #E5E7EB;
    border-radius: 4px;
  }
  [class*=MultiValueRemove] {
    color: #6B7280;
    &:hover {
      color: #F05252;
      background: transparent;
    }
  }
  [class*=indicatorContainer] {
    cursor: pointer;
  }
  button {
    &.bg-blue-600 {
      border: 1px solid #34A9DD;
      border-radius: 30px;
      background: #34A9DD;
      color: #ffffff;
      &:hover {
        background: transparent;
        color: #34A9DD;
      }
    }
    &.text-blue-600 {
      border: 1px solid #E5E7EB;
      border-radius: 30px;
      color: #374151;
      background: #E5E7EB;
      &:hover {
        opacity: 0.7;
      }
    }

  }
}
