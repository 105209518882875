.button-enroll {
     button {
         border-radius: 30px;
         width: 100% !important;
    }
}
.button-started {
      button.bg-blue-700 {
        width: 100% !important;
        background-color:#1C64F2;
    }
}
body {
    min-height: 100%;
    display: flex;
    flex-direction: column;
}
.card-enroll {
    max-width: 420px;
}

.course-details-tabs {
    button {
        border: none;
        background: transparent;
        &.text-gray-500 {
            color: #6B7280;
            font-size: 14px;
            font-weight: 600;
        }

        &.text-blue-600 {
            color: #111928;
            font-size: 14px;
            font-weight: 600;
            border-bottom: 2px solid #111928;
        }

        &:hover {
            background: transparent;
        }
        &:focus-visible {
            outline: none;
        }
    }
}

.tab-group {
    button {
        background-color: unset !important;
        border-color: transparent !important;
        border-radius: unset !important;
        &[aria-selected = "true"]{
            border-color: #1C64F2 !important;
        }
    }

}
@media screen and (max-width: 3600px) {
    .container-card-enroll {
        position: absolute;
        top: 96px;
        right: 32px;
        width: 420px;
    }
      .container-course {
        width: 70%;

    }
}

@media screen and (max-width: 1300px) {
     .container-course {
        width: 100%;
    }

    .container-card-enroll {
        position: static;
        padding: 30px 50px 0 50px;
        width: auto;
    }

    .card-enroll {
        max-width: 100%;
        width: 100%;

        .container-card {
            display: flex;
            justify-content: space-between;

            .information {
                width: 50%;
            }

            .enroll {
                width: 50%;
            }
        }
    }

    .includes {
        display: flex;
        flex-wrap: wrap;

        .degree-item {
            width: 50%;
        }

        .degree-item-icon {
            min-width: 32px;
        }
    }

}

@media screen and (max-width: 640px) {
     .container-course {
        width: 100%;
    }
    .container-card-enroll {
        width: 100%;

        .card-enroll {
            max-width: 100%;
            width: 100%;

            .container-card {
                display: block;

                .information {
                    width: 100%;
                }

                .enroll {
                    width: 100%;
                }
            }
        }
           .includes {
        display: block;

        .degree-item {
            width: 100%;
        }

        .degree-item-icon {
            min-width: 32px;
        }
    }
    }
    .container-degree-courses {
        display: block;
        .instructor {
            width: 40%;
        }
        .summary-course {
            margin-bottom: 0;
        }
    }

}

.openedx-details {
    section {
        display:block;
    }

    article {
        display: block;
    }
    p {
        margin-bottom:10px;
    }
    h2 {
        margin-top:30px;
    }
    .teacher{
        margin-bottom:40px;

        h3 {
            color: #313131;
            font-family: "Open Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
            font-weight: 700;
            margin-bottom: 15px;
            text-transform: none;
        }
        :after {
            content: "";
            display: table;
            clear: both;
        }
    }
    .teacher-image{
        background: #fff;
        border: 1px solid #c8c8c8;
        height: 115px;
        float: left;
        margin: 0 15px 0 0;
        overflow: hidden;
        padding: 1px;
        width: 115px;

        img {
            display:block;
            min-height:100%;
            max-width: 100%;
        }
    }
    .faq {
        article {
            margin-bottom:40px;
        }
        h3 {
            font-family: "Open Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
            font-weight: 700;
            margin-bottom: 15px;
        }
    }
}
