.renew-warning-modal {
    padding: 0 2rem 2rem 2rem;

    .button-renew {
        &.bg-blue-700 {
            border: 1px solid #F05252;
            border-radius: 30px;
            background: #F05252;
            color: #ffffff;

            &:hover {
                background: transparent;
                color: #F05252;
            }
        }

        &.bg-blue-600 {
            border: 1px solid #F05252;
            border-radius: 30px;
            background: #F05252;
            color: #ffffff;

            &:hover {
                background: transparent;
                color: #F05252;
            }
        }

        &:focus {
            box-shadow: unset !important;
        }
    }

    .button-change,
    .button-upgrade {
        &.bg-blue-700 {
            border: 1px solid #34A9DD;
            border-radius: 30px;
            background: #34A9DD;
            color: #ffffff;

            &:hover {
                background: transparent;
                color: #34A9DD;
            }
        }

        &.bg-blue-600 {
            border: 1px solid #34A9DD;
            border-radius: 30px;
            background: #34A9DD;
            color: #ffffff;

            &:hover {
                background: transparent;
                color: #34A9DD;
            }
        }
    }

    // .renew-warning-icon{
    //     svg{
    //         margin: auto;
    //     }
    // }

    .center-horizontal{
        justify-content: center;
        gap: 20px;
    }

    .renew-warning-text{
        text-align: center;
        padding: 1rem;

        .renew-warning-text-summary{
            font-size: 24px;
            font-weight: 500;
            line-height: 30px;
        }

        .renew-warning-text-content{
            font-size: 16px;
            font-weight: 400;
        }
        .color-red {
            color: #F05252;
        }

        div{
            margin-bottom: 30px;
        }
    }
}