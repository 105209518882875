@import '~flowbite';
@tailwind base;
@tailwind components;
@tailwind utilities;


:root {
  --theme-color: #111928;
}
@layer base {
  h1 {
    @apply text-2xl;
  }
  h2 {
    @apply text-xl;
  }
  h3 {
    @apply text-lg;
  }
}

html, body, #root {
  height: 100%;
  background: white;
  font-family: Inter,ui-sans-serif,system-ui,-apple-system,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
  font-size: 16px;
  color: var(--theme-color);
}
.App {
height: 100%;
}
.App>div:nth-child(1) {

    display: flex;
    flex-direction: column;
    height: 100%;
}
.overflow-y {
  overflow-y: scroll;
}
.wrapper-content {
  min-height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
}
.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}
.p-50{
  padding: 50px;
}
.mt-50{
  margin-top: 50px;
}
.mb-50{
  margin-bottom: 50px;
}
.mb-30{
  margin-bottom: 30px;
}
.mb-20{
  margin-bottom: 20px;
}
.w-screen {
  width: 435px;
}

