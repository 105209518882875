.renew-currency-modal {
    padding: 20px 30px 40px 30px;
    .renew-currency-modal-title{
        text-align: center;

        .renew-currency-modal-title-text{
            font-size: 24px;
            font-weight: 400;
            line-height: 24px;
        }
    }

    .renew-currency-modal-guide{
        text-align: center;
        margin: 30px 0px;
        span {
            font-size: 16px;
            font-weight: 400;
            line-height: 16px;
        }
    }

    .renew-currency-modal-select {
        text-align: center;
        font-size: 48px;
        line-height: 48px;
        margin-bottom: 30px;
        
        .dropdown-item {
            padding-right: 2px;
            width: 120px;
            height: 250px;
            overflow-y: scroll;
        }

        > div {
            margin: auto;
        }

        sup.plan-currency {
            font-size: 26px;
        }

        .plan-currencies{
            font-weight: 800;
        }
    }

    .renew-currency-modal-buttons {
        >div {
            justify-content: center;
            display: flex;
            flex-flow: row;
            gap: 20px;
        }

        .button-apply {
            button {
                &.bg-blue-700 {
                    border: 1px solid #34A9DD;
                    border-radius: 30px;
                    background: #34A9DD;
                    color: #ffffff;
    
                    &:hover {
                        background: transparent;
                        color: #34A9DD;
                    }
                }
    
                &.bg-blue-600 {
                    border: 1px solid #34A9DD;
                    border-radius: 30px;
                    background: #34A9DD;
                    color: #ffffff;
    
                    &:hover {
                        background: transparent;
                        color: #34A9DD;
                    }
                }
            }
        }

        .button-cancel {
            &.bg-blue-700 {
                border: 1px solid #E5E7EB;
                border-radius: 30px;
                background: #E5E7EB;
                color: #000000;

                &:hover {
                    background: transparent;
                    color: #E5E7EB;
                }
            }

            &.bg-blue-600 {
                border: 1px solid #E5E7EB;
                border-radius: 30px;
                background: #E5E7EB;
                color: #000000;

                &:hover {
                    background: transparent;
                    color: #E5E7EB;
                }
            }
        }
    }

    .price-dropdown-renew {
        ::-webkit-scrollbar {
          width: 7px;
          display: block;
          background: unset !important;
        }
      
        ::-webkit-scrollbar-thumb {
          background: #666666;
          border-radius: 10px;
        }
      }
}