.ml-auto {
    margin-left: auto;
  }
  
  // custom slide
  $slide-width: 21.8rem;
  
  .carousel__wrap {
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: center;
    position: relative;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .carousel__inner {
    height: 20rem;
    position: relative;
    width: calc(#{$slide-width * 4});
  }
  
  .carousel__container {
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;
  }
  
  .carousel__slide-list {
    height: 100%;
    left: 50%;
    list-style-type: none;
    margin: 0;
    padding: 0;
    position: absolute;
    transform: translateX(-50%);
    width: 100%;
  }
  
  .carousel__slide-item {
    display: inline-block;
    height: $slide-width;
    margin: 0;
    padding: 1rem;
    position: absolute;
    transition: all 0.3s;
    width: $slide-width;
  }
  
  .carousel__slide-item-img-link {
    cursor: zoom-in;
    display: flex;
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;
  
    img {
      height: 100%;
      object-fit: cover;
      transition: all 0.5s ease;
      width: 100%;
    }
  
    &::after {
      align-items: center;
      background: rgba(black, 0.5);
      color: white;
      content: 'read more';
      display: flex;
      height: 100%;
      justify-content: center;
      opacity: 0;
      position: absolute;
      transition: all 0.5s ease;
      width: 100%;
    }
  
    &:hover {
      &::after {
        opacity: 1;
      }
  
      img {
        transform: scale(1.3);
      }
    }
  }
  
  .carousel-slide-item__body {
    bottom: -2.5rem;
    height: 10%;
    position: absolute;
  
    h4 {
      margin: 0.7rem 0 0;
      text-transform: uppercase;
    }
  
    p {
      font-size: 1.2rem;
      line-height: 1.3;
      margin: 0.7rem 0 0;
    }
  }
  
  .carousel__btn {
    width: 54px;
    height: 54px;
    background: #FFFFFF;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px -1px rgba(0, 0, 0, 0.1);
    border-radius: 100px;
    z-index: 1;
  
    align-items: center;
    border: 0;
    cursor: pointer;
    display: flex;
    justify-content: center;
    position: absolute;
    top: 47%;
    transform: translateY(-50%);
  
    &--prev {
      left: -24px;
    }
  
    &--next {
      right: -24px;
    }
  }
  
  .carousel__btn-arrow {
    border: solid black;
    border-width: 0 0.4rem 0.4rem 0;
    height: 6rem;
    padding: 3px;
    width: 6rem;
    z-index: 10;
  
    &--left {
      transform: rotate(135deg);
    }
  
    &--right {
      transform: rotate(-45deg);
    }
  }
  
  .carousel__dots {
    display: inline-block;
    left: 50%;
    margin-top: 2rem;
    position: absolute;
    transform: translateX(-50%);
  
    .dot {
      background: #ccc;
      border: 0;
      border-radius: 50%;
      cursor: pointer;
      height: 2rem;
      margin: 0 0.3rem;
      outline: none;
      transform: scale(0.5);
      width: 2rem;
  
      &.active {
        background: black;
      }
    }
  }
  
  @media (max-width: 1023px) {
    .carousel__inner {
      width: calc(#{$slide-width * 2});
    }
  }
  
  @media (max-width: 567px) {
    .carousel__inner {
      width: calc(#{$slide-width * 1});
    }
  
    .carousel__slide-list {
      width: calc(#{$slide-width * 2});
    }
  
    .carousel__btn {
      &--prev {
        left: -4px;
      }
  
      &--next {
        right: -4px;
      }
    }
  }
  