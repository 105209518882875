.basic-layout {
  .primary{
    color: #34A9DD;
  }
  .hover\:text-blue-700:hover, .hover\:text-primary:hover {
    color: #34A9DD;
  }

  .hover\:bg-blue-800:hover {
    --tw-bg-opacity: 1;
    background-color: #34A9DD;
  }

  button.bg-blue-700 {
    -webkit-appearance: button;
    background-color: #34A9DD;
    border-color: #34A9DD;
    border-radius: 30px;
    &:hover {
      background: transparent;
      color: #34A9DD;
    }
  }
  button.bg-blue-600 {
    -webkit-appearance: button;
    background-color: #34A9DD;
    border-color: #34A9DD;
    border-radius: 30px;
    &:hover {
      background: transparent;
      color: #34A9DD;
    }
  }

  button.bg-transparent {
    background: transparent;
  }
  button.text-gray-900 {
    background: transparent;
  }

  [class*=-indicatorSeparator] {
    display: none;
  }
}


.basic-modal {
  [class*=-indicatorSeparator] {
    display: none;
  }
  button {
    &.bg-blue-700 {
      background: #34a9dd;
      color: #fff;
      &:hover {
        background: transparent;
        color: #34A9DD;
      }
    }
    &.bg-blue-600 {
      border: 1px solid #34A9DD;
      border-radius: 30px;
      background: #34A9DD;
      color: #ffffff;
      &:hover {
        background: transparent;
        color: #34A9DD;
      }
    }
    &.text-blue-600 {
      border: 1px solid #34A9DD;
      border-radius: 30px;
      color: #34A9DD;
    }
  }
}
