.course-item{ 
    background-color:  #EBF6FC;
    border: 1px solid rgba(52, 169, 221, 0.4);
    border-radius: 10px
}
.title {
 display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical; 
  width: 200px;
  overflow: hidden;
  height: 50px;
}