.degrees {
    // height: calc(100vh - 69px);
    padding-top: 82px;

    .filter {
        max-width: inherit;
        width: 100%;
        padding-right: 60px;
        top: 69px
    }

    .bg-filter {
        background-color: #ffffff;

    }

    #scrollableDiv {
        overflow: auto;
    }

    ::-webkit-scrollbar {
        display: none;
    }

    .infinite-scroll-component__outerdiv {
        max-height: calc(100vh - 69px - 82px);
        min-height: calc(100vh - 69px - 82px);
    }

    .mh-scroll {
        min-height: calc(100vh - 217px - 2.5rem);
    }

    .mh-noresult {
        min-height: calc(100vh - 226px - 2.5rem);
    }

    .loading-scroll {
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
    }

    .mb--90 {
        margin-bottom: 60px;
    }


    @media (max-width: 1024px) {
        .infinite-scroll-component__outerdiv {
            max-height: calc(100vh - 85px - 82px);
            min-height: calc(100vh - 85px - 82px);
        }

        .filter {
            max-width: inherit;
            width: 100%;
            padding-right: 60px;
            top: 85px
        }

        .mh-scroll {
            min-height: calc(100vh - 233px - 2.5rem);
        }

        .mh-noresult {
            min-height: calc(100vh - 243px - 2.5rem);

        }
    }

    @media (max-width: 767px) {
        .infinite-scroll-component__outerdiv {
            max-height: calc(100vh - 85px - 82px);
            min-height: calc(100vh - 85px - 82px);
        }

        .filter {
            max-width: inherit;
            width: 100%;
            padding-right: 60px;
            top: 85px
        }

        .mh-scroll {
            min-height: calc(100vh - 292px - 2.5rem);
        }

        .mh-noresult {
            min-height: calc(100vh - 300px - 2.5rem);

        }

    }

    @media (max-width: 500px) {
        padding-top: 196px;

        .infinite-scroll-component__outerdiv {
            max-height: calc(100vh - 85px - 196px);
            min-height: calc(100vh - 85px - 196px);
        }

        .filter {
            max-width: inherit;
            width: 100%;
            padding-right: 30px;

        }

        .mh-scroll {
            min-height: calc(100vh - 407px - 2.5rem);

        }

        .mh-noresult {
            min-height: calc(100vh - 417px - 2.5rem);

        }

        .degrees__nav {
            flex-direction: column;
        }

        .degrees__nav-item {
            width: 100%;
            margin: 5px 0;
        }
    }

}