.subscription-page {
  background-color: #F3F4F6;
  height: 100%;
  .back {
    &:hover {
      opacity: 0.7;
    }
  }

  .subscription-title {
    padding-bottom: 10px;
  }

  .subscription-description {
    width: 610px;
    max-width: 100%;
  }

  .plans {
    display: flex;
    flex-direction: row;
    justify-content: center;

    @media (max-width: 480px) {
      flex-direction: column;
    }
  }

  .plan-item {
    margin: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    text-align: center;

    &.active {
      width: 335px;
      height: 520px;
      padding: 10px 4px 4px;
      background: linear-gradient(270deg, #B350E2 0%, #26A6D1 100%);
      /* shadow-md */

      box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -2px rgba(0, 0, 0, 0.05);
      border-radius: 10px;

    }

    .plan-item-header {
      text-align: center;
      color: white;
      padding-bottom: 10px;

    }

    .plan-item-body {
      background-color: white;
      width: 325px;
      height: 475px;
      padding: 30px;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      .plan-title {
        margin-bottom: 16px;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        height: 48px;
      }

      .plan-currencies {
        color: #111928;
        font-weight: 800;
        font-size: 48px;

        .plan-currency {
          font-weight: 800;
          font-size: 26px;
        }
      }

      .plan-currencies-small {
        color: #111928;
        font-weight: 800;
        font-size: 40px;

        .plan-currency {
          font-weight: 800;
          font-size: 22px;
        }
      }

      .plan-interval {
        margin-top: 16px;
        margin-bottom: 30px;
        font-weight: 400;
        font-size: 16px;
        color: #6B7280;
      }

      .plan-action {
        margin-bottom: 35px;

        button {
          font-weight: 700;
          font-size: 20px;
          border-radius: 30px;
          white-space: nowrap;
          padding: 5px 15px;
        }

        &.free {
          button {
            background: #FFFFFF;
            color: #111928;
            border: 2px solid #111928;

            &:hover {
              opacity: 0.7;
            }
          }
        }

        &.active {
          button {
            background: linear-gradient(270deg, #B350E2 0%, #26A6D1 100%);
            /* shadow-md */

            box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -2px rgba(0, 0, 0, 0.05);

            &:hover {
              color: white;
              opacity: 0.9;
            }
          }
        }
      }

      .plan-description {
        text-align: left;

        li {
          list-style: none;
          display: flex;
          align-items: center;

          &::before {
            display: flex;
            align-items: center;
            content: "\2022";
            color: #111827;
            margin-right: 5px;
            font-size: 32px;
            line-height: 26px;
            margin-top: -2px;
          }
        }
      }

      .loading-currency {
        height: 72px;
        padding-top: 30px;
      }
    }
  }

  .disable-plan {
    opacity: 0.5;
  }

  .price-dropdown {
    top: 70px !important;
    right: 0 !important;
    left: unset !important;
    padding-right: 5px;

    ::-webkit-scrollbar {
      width: 7px;
      display: block;
      background: unset !important;
    }

    ::-webkit-scrollbar-thumb {
      background: #666666;
      border-radius: 10px;
    }
  }

  .dropdown-item {
    padding-right: 2px;
    width: 120px;
    height: 250px;
    overflow-y: scroll;
  }

  .price-dropdown-container {
    top: 40px !important;
    right: 0 !important;
    left: unset !important;
    padding-right: 5px;

    ::-webkit-scrollbar {
      width: 7px;
      display: block;
      background: unset !important;
    }

    ::-webkit-scrollbar-thumb {
      background: #666666;
      border-radius: 10px;
    }
  }
}



.modal-price-change {
  .modal-header {
    justify-content: unset !important;

    h3 {
      width: 80% !important;
      margin-left: 10%;
      display: flex;
      justify-content: center;
    }
  }

  .modal-body {
    padding: 1.5rem 2.5rem;

    .button-continue {
      button {
        width: 70px;

        &.bg-blue-700 {
          border: 1px solid #34A9DD;
          border-radius: 30px;
          background: #34A9DD;
          color: #ffffff;

          &:hover {
            background: transparent;
            color: #34A9DD;
          }
        }

        &.bg-blue-600 {
          border: 1px solid #34A9DD;
          border-radius: 30px;
          background: #34A9DD;
          color: #ffffff;

          &:hover {
            background: transparent;
            color: #34A9DD;
          }
        }
      }
    }

    .button-paynow {
      button {
        width: 100px;

        &.bg-blue-700 {
          border: 1px solid #34A9DD;
          border-radius: 30px;
          background: #34A9DD;
          color: #ffffff;

          &:hover {
            background: transparent;
            color: #34A9DD;
          }
        }

        &.bg-blue-600 {
          border: 1px solid #34A9DD;
          border-radius: 30px;
          background: #34A9DD;
          color: #ffffff;

          &:hover {
            background: transparent;
            color: #34A9DD;
          }
        }
      }
    }

    .button-downgradePlan {
      button {
        width: 150px;

        &.bg-blue-700 {
          border: 1px solid #34A9DD;
          border-radius: 30px;
          background: #34A9DD;
          color: #ffffff;

          &:hover {
            background: transparent;
            color: #34A9DD;
          }
        }

        &.bg-blue-600 {
          border: 1px solid #34A9DD;
          border-radius: 30px;
          background: #34A9DD;
          color: #ffffff;

          &:hover {
            background: transparent;
            color: #34A9DD;
          }
        }
      }
    }

    .button-cancel {
      &.bg-blue-700 {
        border: 1px solid #E5E7EB;
        border-radius: 30px;
        background: #E5E7EB;
        color: #374151;

        &:hover {
          background: transparent;
          color: #34A9DD;
        }
      }

      &.bg-blue-600 {
        border: 1px solid #34A9DD;
        border-radius: 30px;
        background: #34A9DD;
        color: #ffffff;

        &:hover {
          background: transparent;
          color: #34A9DD;
        }
      }
    }
  }

  .price-overview {
    justify-self: center;
    box-sizing: border-box;
    width: 100%;
    border: 1px solid #E5E7EB;
    border-radius: 10px;

    .old-plan {
      padding: 15px 20px;
      width: 100%;
      border-style: solid;
      border-color: #E5E7EB;
      border-radius: 10px 10px 0px 0px;
    }

    .no-plan {
      padding: 18px 20px;
      font-weight: 400;
      font-size: 14px;
    }



    .row {
      border-bottom: 1px solid #E5E7EB;
      padding: 10px 20px;
    }

    .last {
      border-bottom: unset;
      background-color: rgba(52, 169, 222, 0.1);

      span {
        color: #34A9DE;
      }

    }


  }

  .list-course {
    .card-item {

      img {
        max-width: 100%;
        height: 100px;
        margin: 0 auto;
      }

      span {
        font-weight: 400;
        font-size: 0.875rem
      }

      p {
        font-size: 1rem
      }

      .time {

        p {
          font-size: 0.875rem !important
        }
      }

      .progress {
        padding: 0.25rem 0.75rem
      }

      .program-title {
        padding: 0;
        margin: 0
      }
    }

    .card-item>div {
      padding-left: 0.75rem;
      padding-right: 0.75rem;
    }


  }
}