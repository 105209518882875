.course-edit-modal {
  height: 100%;

  [class*="indicatorSeparator"] {
    display: none;
  }
  
  >div {
    overflow: auto;
    max-height: 100vh;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  .course-edit-header {
    .header-title {
      h3 {
        padding-left: 30px;
        padding-right: 30px;
      }
    }
  }

  .course-edit-modal-body {
    padding: 30px;

    input[type="checkbox"] {
      width: 22px;
      height: 22px;
      border: 1px solid #6B7280;
      border-radius: 4px;
      color: white;
      box-shadow: none;

      &:checked {
        border: 1px solid #34A9DD;
        color: #34A9DD;
      }
    }
  }

  .actions {
    button {
      border: 1px solid #34A9DD;
      border-radius: 8px;

      &.bg-blue-600:hover {
        background: #34A9DD;
        opacity: 0.9;
        color: #ffffff;
      }
    }
  }

}

@media (max-width: 767px) {
  .course-edit-modal>div>div {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
  }
}