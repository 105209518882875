body { 
    min-height: 100%;
    display: flex;
    flex-direction: column;
}
.myLearning {
.search-input {
    input {
        border-radius: 30px !important;
        padding-left: 20px;
        padding-right: 20px;
    }
}

.tab-group {
    button {
        background-color: unset !important;
        border-color: transparent !important;
        border-radius: unset !important;
        &[aria-selected = "true"]{
            border-color: #1C64F2 !important;
        }
    }
    div:nth-child(2) > div {
            padding-left: unset !important;
            padding-right: unset !important;
    }

}
.carousel__inner{
    height: 25rem !important;
}
}
