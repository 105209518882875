.user-layout {
  .primary{
    color: #34A9DD;
  }
  .hover\:text-blue-700:hover, .hover\:text-primary:hover {
    color: #34A9DD;
  }
  button.bg-blue-700{
    background-color: #34A9DD;
  }
  .hover\:bg-blue-800:hover {
    --tw-bg-opacity: 1;
    background-color: #34A9DD;
  }

  button.bg-blue-600 {
    -webkit-appearance: button;
    background-color: #34A9DD;
    border-color: #34A9DD;
    border-radius: 30px;
    &:hover {
      background: transparent;
      color: #34A9DD;
    }
  }

  button.bg-transparent {
    background: transparent;
  }
  button.text-gray-900 {
    background: transparent;
  }
  [class*=-indicatorSeparator] {
    display: none;
  }
  [class*=-control] {
    border-radius: 8px;
  }
  .children {
    padding-top: 69px;
  }
      @media (max-width: 1024px) {
          .children {

            padding-top: 85px;
          }
      }
}


.basic-modal {
  [class*=-indicatorSeparator] {
    display: none;
  }
  button {
    border-radius: 30px;
    &.bg-blue-700 {
      background: #34a9dd;
      border: 1px solid #34A9DD;
      color: #fff;
      &:hover {
        background: transparent;
        color: #34A9DD;
      }
    }
    &.bg-blue-600 {
      border: 1px solid #34A9DD;
      border-radius: 30px;
      background: #34A9DD;
      color: #ffffff;
      &:hover {
        background: transparent;
        color: #34A9DD;
      }
    }
    &.text-blue-600 {
      border: 1px solid #34A9DD;
      border-radius: 30px;
      color: #34A9DD;
    }

    &.border-gray-300 {
      color: #34A9DD;
      border: 1px solid #34A9DD;
    }
  }
}
