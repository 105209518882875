.capitalize {
  text-transform: capitalize;
}

@media (max-width: 1600px) {
  .custom-w-full {
    width: 60%;
  }
}

@media (min-width: 0) and (max-width: 1300px) {
  .custom-w-full {
    width: 76%;
  }
}

@media (min-width: 0) and (max-width: 1100px) {
  .custom-w-full {
    width: 100%;
  }
}

//media
@media screen and (max-width: 1024px) {
  .hidden-mobile-tablet {
    display: none;
  }
}

@media screen and (max-width: 640px) {
  .hidden-mobile {
    display: none;
  }

  .w-1\/2 {
    width: 100%;
  }
}

/*Toast*/
.Toastify__toast-icon {
  width: 24px !important;
}

.Toastify__close-button--light {
  color: #111827 !important;
  opacity: 1 !important;
  align-self: center !important;
}

.Toastify__toast {
  border-radius: 10px !important;
  padding: 0 15px !important;
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -2px rgba(0, 0, 0, 0.05) !important;
}

.Toastify__toast-container {
  padding: 0 !important;
}

.Toastify__toast-theme--light {
  color: #111827 !important;
  font-size: 14px !important;
}

.Toastify__toast-body {
  padding: 0 !important;
}
.a{
 margin-top: 200px;
}
//
.break-word {
  word-break: break-word;
}

// Override dropdow css - START

.css-13cymwt-control {
  border: 1px solid #F3F4F6 !important;
  background: #F9FAFB !important;
  border-radius: 8px !important;
}

.css-b62m3t-container.errors {
  .css-13cymwt-control {
    border: 1px solid red !important;
    background-color: rgb(253 242 242) !important;
    border-radius: 8px !important;
  }

  .css-13cymwt-control:hover{
    border: 1px solid red !important;
  }
  
  .css-13cymwt-control:focus{
    border: 1px solid red !important;
  }

  .css-t3ipsp-control {
    border: 1px solid red!important;
    box-shadow: 0 0 0 1px red !important;
    background-color:  rgb(253, 242, 242) !important;
  }
  
  .css-t3ipsp-control:hover {
    border: 1px solid red !important;
    box-shadow: 0 0 0 1px red !important;
    background-color:  rgb(253, 242, 242) !important;
  }

  .css-t3ipsp-control:focus{
    border: 1px solid red !important;
    background-color:  rgb(253, 242, 242) !important;
  }
}

.css-1jqq78o-placeholder,
.css-1dimb5e-singleValue {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #111928 !important;
  line-height: 150% !important;
  margin: 0 !important;
}

.css-1xc3v61-indicatorContainer {
  color: #6B7280 !important;
}

.css-1fdsijx-ValueContainer {
  padding: 8px 15px !important;
}

.css-1nmdiq5-menu {
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #111928 !important;
}

.css-t3ipsp-control {
  border: 1px solid #34A9DD !important;
  box-shadow: 0 0 0 1px #34A9DD !important;
}

.css-t3ipsp-control:hover {
  border: 1px solid #34A9DD !important;
  box-shadow: 0 0 0 1px #34A9DD !important;
}


.css-10wo9uf-option .input-checkbox-course {
  border-radius: 4px;
}

.css-d7l1ni-option,
.css-tr4s17-option {
  background: #34A9DD1A !important;
  color: #111928 !important;

  .input-checkbox-course {
    border-radius: 4px;
  }
}

.custom-button-style{
  background-color: #1B65F2;
  transition: background-color 0.2s ease-in-out;
  &:active{
    background-color: #34A9DD;
  }
  &:focus{
    background-color: #34A9DD;
  }
  &:hover {
    background-color: #34A9DD;
  }
}

.card-item img {
  max-width: 100%;
  height: 170px;
  margin: 0 auto;
  width:100% !important;
}

// Override dropdow css - END
