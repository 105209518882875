body {
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

.myDegree {
    .title {
        width: 100%;
        height: 82px;
        z-index: 10;
    }

    .search-input {
        input {
            border-radius: 30px !important;
            padding-left: 20px;
            padding-right: 20px;
        }
    }

    .tab-group {
        button {
            background-color: unset !important;
            border-color: transparent !important;
            border-radius: unset !important;

            &[aria-selected="true"] {
                border-color: #1C64F2 !important;
            }
        }

        div:nth-child(2)>div {
            padding-left: unset !important;
            padding-right: unset !important;
        }

    }

    #scrollableDiv {
        overflow: auto;
    }

    ::-webkit-scrollbar {
        display: none;
    }

    .infinite-scroll-component__outerdiv {
        max-height: calc(100vh - 69px);
        min-height: calc(100vh - 69px);
    }

    .mh-scroll {
        min-height: calc(100vh - 216px);
    }

    .mh-noresult {
        min-height: calc(100vh - 392px);
    }

    .loading-scroll {
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
    }

    .mb--90 {
        margin-bottom: 60px;
    }

    @media (max-width: 1024px) {
        .infinite-scroll-component__outerdiv {
            max-height: calc(100vh - 85px);
            min-height: calc(100vh - 85px);
        }

        .mh-scroll {
            min-height: calc(100vh - 232px);
        }

        .mh-noresult {
            min-height: calc(100vh - 411px);
        }

    }

    @media (max-width: 767px) {
        .infinite-scroll-component__outerdiv {
                max-height: calc(100vh - 85px);
                min-height: calc(100vh - 85px);
            }
        .mh-scroll {
            min-height: calc(100vh - 315px);
        }

        .mh-noresult {
            min-height: calc(100vh - 320px);
        }
    }

    @media (max-width: 500px) {
        .infinite-scroll-component__outerdiv {
                max-height: calc(100vh - 85px);
                min-height: calc(100vh - 85px);
            }ss
        .mh-scroll {
            min-height: calc(100vh - 447px);
        }

        .mh-noresult {
            min-height: calc(100vh - 452px);
        }

        .degrees__nav {
            flex-direction: column;
        }

        .degrees__nav-item {
            width: 100%;
            margin: 5px 0;
        }
    }
}