.header-page {
    .group-button-header {
      display: flex;
      align-items: center;
  
      button {
        box-shadow: none;
      }
  
      .border {
        border-width: 0;
        padding: 0;
      }
  
      .text-gray-900 {
        color: #34a9dd;
      }
  
      .bg-green-700 {
        background-color: #ebf5ff;
        color: #34a9dd;
      }
    }
  
    .pointer-item {
      cursor: pointer;
    }
  
    .avatar{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 37px;
      height: 37px;
      background: #ff8a4c;
      border-radius: 30px;
      text-transform: uppercase;
      color: white;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
    }
  }
