.banner-search {
  background-image: url("../../assets/banner/bannerSearch.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 50px 0;

  .search {
    width: 38%;

    input {
      border-radius: 30px !important;
      padding: 18px 25px;
    }
    input::placeholder {
      color: #111928 !important;
    }

    div > div > div {
      padding-right: 1.75rem !important;
    
      svg {
        width: 1.5rem !important;
        height: 1.5rem !important;
        color: #111928 !important;

      }
    }
  }

}

.custom-w-screen {
  width: 1440px;
}

// for smaller tablet
@media (max-width: 1300px) {
  .banner-search {
    background-size: auto;
    padding: 40px 0;

    .search {
      width: 50%;

      input {
        padding: 15px 20px;
      }
    }
  }
}

// for smaller tablet
@media (max-width: 1366px) {
  .custom-w-screen {
    width: 100%;
  }
}

// for mobile
@media (max-width: 567px) {
  .banner-search {
    padding: 20px 0;

    .search {
      width: 80%;

      input {
        padding: 15px 20px;
      }
    }
  }

  .custom-w-screen {
    width: 100%;
  }
}

.max-screen {
  max-width: 1440px;
  padding-left: 30px;
  padding-right: 30px;
}

@media (max-width: 567px) {
  .max-screen {
    max-width: 567px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.p-screen {
  padding-left: 80px;
  padding-right: 80px;
}

// for small laptop
@media (max-width: 1000px) {
  .p-screen {
    padding-left: 40px;
    padding-right: 40px;
  }
}

// for mobile
@media (max-width: 567px) {
  .p-screen {
    padding-left: 15px;
    padding-right: 15px;
  }
}